<template>
  <div class="FormEntryForm">
    <el-form ref="dynamicForm" :model="dynamicForm" size="small" label-position="top">
      <div class="formCon" v-for="(groupItem,key) in dynamicForm.sectionDDMap" :key="key">
        <div v-if="groupItem.isRepeat ==='0'" v-show="Object.keys(groupItem.groupMap).length>0&&!groupItem.hiden">
          <div v-for="(item,kk,index) in groupItem.groupMap" :key="item.ddCode">
            <p class="formLabel" v-if="item.controlType==='LB'" :style="{fontSize:item.numConfig}">{{item.ddName}}</p>
            <div class="tag-wrap">
              <div class="tag-top-wrap">
                <p v-if="item.ddName&&!dynamicForm.singleSectionConditionMap[kk].hided" class="icont-box" :class="[item.remark?'icon-note1':'icon-note0']" @click="handleNote(item,1)"></p>
                <div v-authorizeRole="{'itemCode':'crf_data_sdv',menuContainer:$authorContainer}" v-if="isShowBTN && item.sdv === 'true' && item.ddName&&!dynamicForm.singleSectionConditionMap[kk].hided">
                  <p style="width:18px;height:18px;" class="icont-box" :class="[item.sdvStatus?'icon-sdv1':'icon-sdv0']" @click="onChangeSDV(item)" size="mini">
                  </p>
                </div>
                <!-- <el-button type="primary" size="mini" class="qabtn"> -->
                <i v-if="item.ddName&&!dynamicForm.singleSectionConditionMap[kk].hided" class="question-icon-wrap" :class="['icon'+(item.question ? item.question:0)]" @click="onQuestion(item,index)"></i>
                <!-- </el-button> -->
                <p v-if="item.ddName&&!dynamicForm.singleSectionConditionMap[kk].hided" class="icont-box" :class="[isShowBTN?'icon-trail1':'icon-trail0']" @click="onTrailList(item)" style="margin-left:12px;"></p>
              </div>
              <!--单文本-->
              <!-- item.controlType==='TB'&&!dynamicForm.singleSectionConditionMap[kk].hided -->
              <el-form-item v-if="item.controlType==='TB'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
                <el-input :disabled="(formType=='Single'&&item.dataType==1)||(formType=='Multiple'&&dialogStatus=='check')||formStatus!=0" :id="item.ddCode" type="input" v-model="item.value">
                </el-input>
              </el-form-item>
              <!-- 多行文本 -->
              <!-- item.controlType==='TA'&&!dynamicForm.singleSectionConditionMap[kk].hided -->
              <el-form-item v-if="item.controlType==='TA'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="[ {required: item.isImExport===1, message: `${item.ddName}不能为空`, trigger: 'blur'},
                            { max: 255, message: '最多不超过255个字', trigger: 'blur' } ]">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <el-input :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" type="textarea" v-model="item.value" rows="2"></el-input>
              </el-form-item>
              <!-- 单选 v-if="item.controlType==='RB'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item v-if="item.controlType==='RB'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <el-radio-group :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value">
                  <template v-for="(listItem,index) in item.itemOptionList">
                    <el-radio :label="listItem.displayName" :key="listItem.pkId">
                      {{listItem.displayName}}
                    </el-radio>
                    <el-image :key="index" style="width: 20px; height: 20px" :src="imageNameFormat(listItem.optionSetImgUrl,'0')" :preview-src-list="imageNameFormat(listItem.optionSetImgUrl,'1')" v-if="listItem.optionSetImgUrl">
                    </el-image>
                  </template>
                </el-radio-group>
              </el-form-item>
              <!-- 多选  v-if="item.controlType==='CB'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item :readonly="item.dataType==1 || readonlyStatus" v-if="item.controlType==='CB'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
                <el-checkbox-group :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value">
                  <template v-for="(listItem,indeox) in item.itemOptionList">

                    <div class="el-checkbox" :key="indeox">
                      <el-checkbox :label="listItem.displayName">
                        {{listItem.displayName}}
                      </el-checkbox>
                    </div>
                    <el-image :key="indeox" style="width: 20px; height: 20px" :src="imageNameFormat(listItem.optionSetImgUrl,'0')" :preview-src-list="imageNameFormat(listItem.optionSetImgUrl,'1')" v-if="listItem.optionSetImgUrl">
                    </el-image>
                  </template>
                </el-checkbox-group>
              </el-form-item>
              <!-- 下拉 v-if="item.controlType==='SEL_SIG'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item v-if="item.controlType==='SEL_SIG'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <el-select :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value" filterable placeholder="请选择">
                  <el-option v-for="listItem in item.itemOptionList" :key="listItem.value" :label="listItem.displayName" :value="listItem.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 日期选择 -->
              <!-- item.controlType==='DP'&&!dynamicForm.singleSectionConditionMap[kk].hided -->
              <el-form-item v-if="item.controlType==='DP'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <el-date-picker :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" value-format="yyyy-MM-dd" v-model="item.value" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <!-- 时间选择 -->
              <!-- v-if="item.controlType==='TP'&&!dynamicForm.singleSectionConditionMap[kk].hided" -->

              <el-form-item v-if="item.controlType==='TP'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <!-- <el-time-select
                                            @blur="calItemBlur(item)"
                                            @focus="calItemFocus(item)"
                                            :readonly="item.dataType==1 || readonlyStatus"
                                            :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')"
                                            v-model="item.value"
                                            format="HH:mm"
                                            placeholder="选择时间"
                                            :picker-options="{start:'00:00',step:'00:15',end:'23:59'}">
                                    </el-time-select> -->
                <el-time-picker @blur="calItemBlur(item)" @focus="calItemFocus(item)" :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
              </el-form-item>
              <!-- 时间日期选择v-if="item.controlType==='DTP'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item v-if="item.controlType==='DTP'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" value-format="yyyy-MM-dd" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
                <el-date-picker @blur="calItemBlur(item)" @focus="calItemFocus(item)" @change="calItemFocus(item)" :readonly="item.dataType==1 || readonlyStatus" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" value-format="yyyy-MM-dd HH:mm:ss" v-model="item.value" type="datetime" placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
              <!-- 数字 -->

              <el-form-item @click="handleNumItemClick" v-if="item.controlType==='NTX'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
                <el-input :readonly="item.dataType==1 || readonlyStatus" type="number" v-model="item.value" @blur="calItemBlur(item)" @focus="calItemFocus(item)" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')">
                  <template slot="append" v-if="item.unitOfMeasure">{{item.unitOfMeasure}}
                  </template>
                </el-input>
                <!-- <div style="display:flex;align-items: center;">
                                        <p style="display:flex;align-items: center;">
                                            <span :class="{'iDisabled':item.value ==item.numConfig.scopeRange_down&& item.numConfig.allowDecimal!=0}" class="unit-box unit-boxl" @click="onReduceNum(item,-1)"><i class="el-icon-minus"></i></span>
                                            <el-input v-model="item.value" :placeholder="'请输入'+item.ddName" readonly></el-input>
                                            <span :class="{'iDisabled':item.value ==item.numConfig.scopeRange_up && item.numConfig.allowDecimal!=0}" class="unit-box" @click="onReduceNum(item,1)"><i class="el-icon-plus"></i></span>
                                        </p>
                                        <span class="unit-box" v-if="item.unitOfMeasure">{{item.unitOfMeasure}}</span>
                                    </div> -->

              </el-form-item>
              <!-- 文件上传 v-if="item.controlType==='SUF'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item v-if="item.controlType==='SUF'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

                <el-upload :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')|| readonlyStatus" action="/api/ddValue/upload" name="files" :on-change="handleChange" :on-success="handleSuccess" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="item.fileList" multiple :limit="5" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload">
                  <el-button slot="trigger" size="mini" type="primary" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')|| readonlyStatus">
                    文件选取
                  </el-button>
                </el-upload>
              </el-form-item>
              <!-- 地址选择 v-if="item.controlType==='ADSEL'&&!dynamicForm.singleSectionConditionMap[kk].hided"-->
              <el-form-item v-if="item.controlType==='ADSEL'&&!dynamicForm.singleSectionConditionMap[kk].hided" :prop="'sectionDDMap.' + key + '.groupMap.' + kk + '.value'" :rules="itemRule(item)">
                <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
                <el-cascader :disabled="(formType=='Single'&&item.dataType!=0)||(formType=='Multiple'&&dialogStatus=='check')" size="normal" style="width:270px" :options="regionOptions" v-model="item.value">
                </el-cascader>
              </el-form-item>
              <p style="padding-bottom:12px;color:#909399;margin-bottom: 22px;border-bottom: 1px dashed #DCDFE6;" v-if="item.remark">
                <span>备注：{{item.remark}}</span>
                <i style="padding: 0 8px;cursor: pointer;" class="el-icon-edit" @click="handleNote(item,2)"></i>
                <el-popconfirm title="确定删除此备注吗？" @confirm="handleNote(item,0)">
                  <i slot="reference" style="cursor: pointer;" class="el-icon-delete"></i>
                </el-popconfirm>
              </p>
            </div>
          </div>
        </div>

        <div v-else v-show="groupItem.label.length>0 && !dynamicForm.singleSectionConditionMap[groupItem.groupCode].hided" class="table-wrap">
          <el-table :data="Object.values(groupItem.groupMap)" border style="width: 100%" :header-cell-class-name="tableHeaderCn">
            <template>
              <el-table-column v-for="(col,colIdx) in groupItem.label" :key="col.ddCode" :prop="col.ddCode" fixed :label="col.ddName" align="center" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <div class="tag-top-wrap">
                    <span>{{formatterScope(scope,colIdx)}}</span>
                    <el-popover placement="top" trigger="click">
                      <div class="table-e-wrap">
                        <p class="icont-box" style="padding-right:8px;" :class="[scope.row[colIdx].remark?'icon-note1':'icon-note0']" @click="handleNote(scope.row[colIdx],1)"></p>
                        <div v-authorizeRole="{'itemCode':'crf_data_sdv',menuContainer:$authorContainer}" v-if="scope.row[colIdx].sdv === 'true' && isShowBTN">
                          <!-- <el-button class="qabtn" v-if="!scope.row[colIdx].sdvStatus"
                                                                   type="primary" @click="onChangeSDV(scope.row[colIdx])"
                                                                   size="mini">待SDV
                                                        </el-button>
                                                        <el-button class="qabtn" v-else type="success"
                                                                   @click="onChangeSDV(scope.row[colIdx])" size="mini">已SDV
                                                        </el-button> -->
                          <p class="icont-box" :class="[scope.row[colIdx].sdvStatus?'icon-sdv1':'icon-sdv0']" @click="onChangeSDV(scope.row[colIdx])" size="mini">
                          </p>
                        </div>
                        <i class="question-icon-wrap" :class="['icon'+(scope.row[colIdx].question ? scope.row[colIdx].question:0)]" @click="onQuestion(scope.row[colIdx])"></i>
                        <p class="icont-box" style="margin-left:8px;" @click="onTrailList(scope.row[colIdx])" :class="[isShowBTN?'icon-trail1':'icon-trail0']"></p>
                      </div>
                      <i type="primary" style="cursor: pointer;" class="el-icon-more" slot="reference"></i>
                    </el-popover>

                  </div>
                  <div class="note-box-wrap" style="padding-left:0;padding-top:8px;color:#909399;text-align: left;" v-if="scope.row[colIdx].remark">
                    <p class="note-label">备注：{{scope.row[colIdx].remark}}</p>
                    <p class="edit-wrap">
                      <i style="padding: 0 8px;cursor: pointer;" class="el-icon-edit" @click="handleNote(scope.row[colIdx],2)"></i>
                      <el-popconfirm title="确定删除此备注吗？" @confirm="handleNote(scope.row[colIdx],0)">
                        <i slot="reference" style="cursor: pointer;" class="el-icon-delete"></i>
                      </el-popconfirm>
                    </p>
                  </div>
                </template>
              </el-table-column>
            </template>
            <el-table-column fixed="right" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" :disabled="formStatus!=0" size="small" v-authorizeRole="{'itemCode':'crf_data_update',menuContainer:$authorContainer}" @click="formDialog('edit',groupItem.mFormData,scope.$index,groupItem)">
                  <i class="iconfont icon-bianji" title="编辑"></i>
                </el-button>
                <el-button type="text" :disabled="formStatus!=0" size="small" v-authorizeRole="{'itemCode':'crf_data_see',menuContainer:$authorContainer}" @click="formDialog('check',groupItem.mFormData,scope.$index,groupItem)">
                  <i class="iconfont icon-list" title="查看"></i>
                </el-button>
                <el-button type="text" :disabled="scope.row[0].status==1" size="small" v-authorizeRole="{'itemCode':'crf_data_del',menuContainer:$authorContainer}">
                  <el-popconfirm title="您确定要删除该数据吗？" :disabled="scope.row[0].status==1" @confirm="removeForm(groupItem.mFormData[scope.$index].id,groupItem)">
                    <i slot="reference" class="iconfont icon-shanchu" title="删除"></i>
                  </el-popconfirm>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align:right;margin-top:15px" v-if="groupItem.allow">
            <el-button size="mini" :disabled="formStatus!==0" type="primary" @click="formDialog('add',0,groupItem)" v-authorizeRole="{'itemCode':'crf_data_add',menuContainer:$authorContainer}">
              新增
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <div v-if="dynamicForm.sectionDDMap.length >=1" class="bottom-btn-wrap" style=";position:fixed;bottom:16px;right:11px;z-index:2000;">
      <!-- <el-button v-if="dialogStatus!=='check'"
                       :disabled="(formType=='Single'&&formStatus!=0)">返回顶部
            </el-button> -->
      <i class="iconfont icon-upload toTop" @click="backtop"></i>
      <el-button v-if="!isShowBTN" type="primary" @click="handleConfirm({n: 'dynamicForm', s: 0})" v-authorizeRole="{'itemCode':'crf_save',menuContainer:$authorContainer}" :disabled="(formType=='Single'&&formStatus!=0||isFormPreview)" :loading="loading">
        保存
      </el-button>
      <el-button v-authorizeRole="{'itemCode':'crf_submit',menuContainer:$authorContainer}" type="primary" @click="handleConfirm({n: 'dynamicForm', s: 1})" :disabled="(formType=='Single'&&formStatus!=0||isFormPreview)" :loading="submitLoading">
        提交
      </el-button>
    </div>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="formDialogVisible" custom-class="center-dialog">
      <FormEntryForm :formData="dynamicFormDialog" ref="multiForm" :dialogStatus="dialogStatus" formType="Multiple" :sectionList="sectionList" :exampleId="exampleId" :parentExampleId="parentExampleId" :isShowBTN='isShowBTN' :isIndex="isIndex" :researchCode="researchCode" :editKey="customEditKey" :groupCode="groupCode" :ddValueIds="ddValueIds" @closeFrom="closeFrom" @refresh="refresh" :handleItem="handleItem" @refreshTable='refreshTable' :changePatientId="changePatientId"></FormEntryForm>
    </el-dialog>
    <el-dialog :title="questionTitle" :visible.sync="questionDialogShow" :close-on-click-modal='true'>
      <div class="dialog-footer" v-if="questionDialogShow">
        <QuestionToast :qaIndex='qaIndex' :btnSendLoading='btnSendLoading' :btnCloseLoading='btnCloseLoading' ref="qtost" :leftQAList='leftQAList' @onSendquestion='onSendquestion' @addNewQA='addNewQA' @onCloseQuestion='onCloseQuestion' @setIndex='setIndex' :tloading='tloading'></QuestionToast>
      </div>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="noteDialogVisible" width="30%">
      <el-form :model="noteForm" label-width="auto">
        <el-form-item>
          <el-input type="textarea" v-model="noteForm.remark" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="noteDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="onSubmitNote">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {
  stopQuestionList,
  addQuestionList,
  addQuestion,
  getAllQuestionList,
  buttonSDV,
  getSectionList,
  getTreeNodeData,
  addFormEntryGroup,
  submit,
  getStructureDdList,
  getStructureDdListGroup,
  deleteFormEntry,
  editRemark,
} from '@/api/formEntry'
import { handleFormData, formatDate, hasUserRole } from '@/utils/index'
import globalConst from '../utils/globalConst'
import { handleCalculate } from '../utils/logicCalculate'
import { regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import FormLabel from './FormItemLabel'
import FormEntryForm from './FormEntryForm'
import QuestionToast from './QuestionToast'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'FormEntryFormSingle',
  components: { FormLabel, FormEntryForm, QuestionToast },
  props: [
    'isFormPreview',
    'nextItem',
    'onload',
    'sectionList',
    'isIndex',
    'researchCode',
    'editKey',
    'parentExampleId',
    'exampleId',
    'formData',
    'formType',
    'submitLoading',
    'formStatus',
    'handleItem',
    'changePatientId',
    'isShowBTN',
  ],
  data() {
    return {
      noteForm: {},
      noteDialogVisible: false,

      formDtataList: [],
      qaIndex: 0,
      btnSendLoading: false,
      btnCloseLoading: false,
      addQAItem: {},
      tloading: true,
      questionTitle: '质疑',
      questionDialogShow: false,
      firstIn: true,
      fileList: [],
      autoCalculate: true,
      calculateDDTypeArr: ['NTX', 'DP', 'TP', 'DTP'],
      selCalculateDDTypeArr: ['RB', 'CB', 'SEL_SIG'],
      dynamicFormDialog: {
        singleSectionConditionMap: {},
        sectionDDMap: {},
      },
      dynamicForm: {
        singleSectionConditionMap: {},
        sectionDDMap: {},
      },
      loading: false,
      regionOptions: regionDataPlus,
      textMap: {
        update: '编辑表单',
        create: '新增表单',
        check: '查看表单',
      },
      formDialogVisible: false,
      dialogStatus: 'update',
      groupCode: '',
      customEditKey: '',
      ddValueIds: [],
      groupExampleId: '',
      currentNumItem: {},
      groupCodes: [],
      leftQAList: [{ list: [] }],
      readonlyStatus: false,
    }
  },
  created() {
    this.customEditKey = this.editKey
    this.authorUserRoleItem()
  },
  computed: {
    ...mapState(['userData']),
    isPreview: function () {
      return !this.$route.meta.isPreview
    },
    ddCodeReg() {
      return /#(.+?)#/g
    },
    itemRule() {
      return (item) => {
        let rules = [
          {
            required: item.isImExport === 1,
            message: `${item.ddName}不能为空`,
            trigger: ['blur', 'change'],
          },
        ]
        if (item.controlType == 'NTX') {
          rules.push({
            validator: (rule, value, callback) => {
              if (value) {
                let np = /^(-)?\d+(\.\d+)?$/
                if (!np) callback(new Error('数字输入有误'))
                let nc = item.numConfig
                if (nc) {
                  if (nc.allowDecimal == 0) {
                    if (value.toString().indexOf('.') >= 0)
                      callback(new Error('不允许输入小数'))
                  } else {
                    if (value.toString().indexOf('.') >= 0) {
                      let s = value.toString().split('.')[1].length
                      if (s > nc.decimalDigits)
                        callback(
                          new Error('仅允许' + nc.decimalDigits + '位小数')
                        )
                    }
                  }

                  if (
                    nc.scopeValid == 1 &&
                    (parseFloat(value) > parseFloat(nc.scopeRange_up) ||
                      parseFloat(value) < parseFloat(nc.scopeRange_down))
                  ) {
                    callback(
                      new Error(
                        '数值范围输入有误! 数值范围:{' +
                          nc.scopeRange_down +
                          ',' +
                          nc.scopeRange_up +
                          '}'
                      )
                    )
                  }
                }
              }
              callback()
            },
            trigger: ['change', 'blur'],
          })
        }
        return rules
      }
    },
    params() {
      let list = []
      Object.values(this.dynamicForm.sectionDDMap).forEach((gItem) => {
        gItem.isRepeat === '0' &&
          Object.keys(gItem.groupMap).length > 0 &&
          Object.keys(gItem.groupMap).forEach((key) => {
            let item = gItem.groupMap[key]
            // console.log('======', item);
            if (this.formType == 'Single')
              handleFormData(item, 'toStr', this.fileList)
            else handleFormData(item, 'toStr')
            if (this.dynamicForm.singleSectionConditionMap[item.ddCode].hided) {
              item.value = ''
            }
            list.push({
              name: item.ddCode,
              //修改数据时,如果字段值满足条件,修改后不满足,则保存的时候会把隐藏的字段值设置为 ''
              // value: this.dynamicFormDialog.singleSectionConditionMap[item.ddCode].hided ? '' : item.value
              value: item.value,
              remark: item.remark,
              groupCode: item.groupCode,
              pkId: item.pkId,
              exampleId: item.exampleId,
            })
          })
      })
      return {
        sections: [
          {
            parentExampleId: this.parentExampleId,
            sectionCode: this.handleItem.code,
            sectionParentCode: this.handleItem.parentCode,
            registyEntityCode: this.researchCode,
            exampleId: this.exampleId,
            patientId: this.changePatientId,
            mode: 'create',
            ddValueList: list,
            formType: this.formType,
          },
        ],
      }
    },
    multiFormParam() {
      return {
        registryEntityCode: this.researchCode,
        groupCode: this.groupCode,
        selectionType: this.handleItem.selectionType,
        code: this.handleItem.code,
        patientId: this.changePatientId,
        exampleId: this.handleItem.exampleId,
        parentCode: this.handleItem.parentCode,
      }
    },
    imageNameFormat(name, type) {
      return (name, type) => {
        let imageName = ''
        if (name) {
          imageName = this.$host + name.substr(name.lastIndexOf('/'))
        }
        if (type === '0') {
          return imageName
        } else {
          var myArray = new Array()
          myArray.push(imageName)
          return myArray
        }
      }
    },
  },
  methods: {
    formatterList() {
      Array.from(this.formData.sectionDDMap).forEach((gItem) => {
        // console.log(gItem)

        let formList = []

        Object.keys(gItem.groupMap).length > 0
          ? Object.keys(gItem.groupMap).forEach((key) => {
              if (gItem.isRepeat == '1') {
                // console.log(key, 'gItem.groupMap[key]', gItem.groupMap[key]);
                let obj = {}
                let code = ''
                if (gItem.groupMap[key].forEach) {
                  gItem.groupMap[key].forEach((keyItem) => {
                    handleFormData(keyItem, 'strToOther')
                    code = keyItem.ddCode
                    if (keyItem.controlType === 'SEL_SIG') {
                      let selObj = keyItem.itemOptionList.filter(
                        (v) => keyItem.value === v.value
                      )
                      obj[code] =
                        selObj && selObj.length > 0 ? selObj[0].displayName : ''
                    } else if (keyItem.controlType === 'ADSEL') {
                      // let addressArr = '';
                      // keyItem.value.forEach(item => {
                      //     addressArr += CodeToText[item]
                      // });
                      // obj[code] = addressArr;
                    } else if (keyItem.controlType === 'CB') {
                      obj[code] = keyItem.value.join(',')
                    } else {
                      obj[code] = keyItem.value
                    }
                    obj['id'] = key
                    obj['ddValueIds'] = keyItem.ddValueIds || []
                    obj['groupCode'] = keyItem.groupCode
                  })

                  formList.push(obj)
                }
              } else {
                handleFormData(gItem.groupMap[key], 'strToOther') //多选[]
              }
              gItem.mFormData = formList
            })
          : (gItem.mFormData = [])
      })
      this.dynamicForm = { ...this.formData }
      console.log(this.dynamicForm.sectionDDMap)
    },
    delRefreshTable(key) {
      console.log(key, code)
      const idKey = key.key
      const code = key.code
      this.dynamicForm.sectionDDMap.map((e, i) => {
        if (e.groupCode == code) {
          delete e.groupMap[idKey]
        }
      })
      this.formatterList()
    },
    refreshTable(v) {
      console.log(111111, v, this.dynamicForm)
      // this.$emit('refreshTable',v)
      const groupCode = v.groupCode
      const pkKey = Object.keys(v)[0]
      const pkValue = Object.values(v)[0]
      this.dynamicForm.sectionDDMap.map((e, i) => {
        if (e.groupCode == v.groupCode) {
          e.groupMap[pkKey] = pkValue
        }
      })
      this.formatterList()
      this.formDialogVisible = false
    },
    onReduceNum(item, v) {
      let precision = Math.pow(10, item.numConfig.decimalDigits)
      let stap = item.numConfig.allowDecimal
        ? 1 / Math.pow(10, item.numConfig.decimalDigits)
        : 1
      console.log(stap)
      let value = item.value ? item.value : item.numConfig.scopeRange_down
      item.value =
        item.numConfig.allowDecimal == 0
          ? (value * precision + v * stap * precision) / precision
          : ((value * precision + v * stap * precision) / precision).toFixed(
              parseInt(item.numConfig.decimalDigits)
            )
      if (
        item.value <= item.numConfig.scopeRange_down &&
        item.numConfig.allowDecimal != 0
      ) {
        item.value =
          item.numConfig.allowDecimal == 0
            ? item.numConfig.scopeRange_down
            : item.numConfig.scopeRange_down.toFixed(
                parseInt(item.numConfig.decimalDigits)
              )
      }
      if (
        item.value >= item.numConfig.scopeRange_up &&
        item.numConfig.allowDecimal != 0
      ) {
        item.value =
          item.numConfig.allowDecimal == 0
            ? item.numConfig.scopeRange_up
            : item.numConfig.scopeRange_up.toFixed(
                parseInt(item.numConfig.decimalDigits)
              )
      }
    },
    onTrailList(v) {
      let obj = {
        patientId: v.patientId,
        ddCode: v.ddCode,
        sectionCode: v.sectionCode,
        exampleId: v.exampleId,
        submit: this.isShowBTN ? 1 : 0,
      }
      this.$emit('onTrailList', obj)
    },
    handleNote(v, t) {
      if ((v.remark && t == 1) || !this.isPreview) {
        return
      }
      this.noteForm = {
        pkId: v.pkId,
        remark: v.remark,
        submit: this.isShowBTN,
        model: t ? 'UPDATE' : 'DELETE',
        userName: this.userData.userName,
        sectionCode: v.sectionCode,
      }
      if (t == 0) {
        this.onSubmitNote()
        return
      }
      this.noteDialogVisible = true
    },
    onSubmitNote() {
      editRemark({ ...this.noteForm })
        .then((res) => {
          if (res.code == 200) {
            this.$emit('refresh')
            this.noteDialogVisible = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formatterVisable(item) {
      return item.hiden
    },
    //控制表单里面元素只读
    authorUserRoleItem() {
      let authorContainer = this.$authorContainer
      if (authorContainer.environment !== '0') {
        if (hasUserRole(authorContainer.codeContainer, 'crf_save') === -1) {
          if (!globalConst.devEnv) return (this.readonlyStatus = true)
        }
      }
      //                return false;
    },
    formatterScope(s, i) {
      if (s.row[i].controlType === 'ADSEL') {
        let addressArr = ''
        Array.isArray(s.row[i].value) &&
          s.row[i].value.forEach((item) => {
            if (CodeToText[item]) {
              addressArr += ' ' + CodeToText[item]
            }
          })
        return addressArr
      }
      if (Array.isArray(s.row[i].value)) {
        return s.row[i].value.join(',')
      }

      if (s.row[i].controlType === 'SEL_SIG') {
        let arr = s.row[i].itemOptionList.filter((e) => {
          return e.value == s.row[i].value
        })
        if (arr.length) {
          return arr[0].displayName
        }
      } else {
        if (s.row[i].controlType === 'DP' && s.row[i].value) {
          return this.$time(new Date(s.row[i].value)).format('YYYY-MM-DD')
        }
        if (s.row[i].controlType === 'TP' && s.row[i].value) {
          if (isNaN(this.$time(s.row[i].value).hour())) {
            return s.row[i].value
          } else {
            return this.$time(new Date(s.row[i].value)).format('HH:mm:ss')
          }
        }
        if (s.row[i].controlType === 'DP' && s.row[i].value) {
          return this.$time(new Date(s.row[i].value), 'YYYY-MM-DD HH:mm:ss')
        }
        return s.row[i].value
      }
    },
    setIndex(i) {
      // this.qaIndex = i
      this.$set(this, 'qaIndex', i)
    },
    setSend(bool) {
      this.$set(this.leftQAList[this.qaIndex], 'canSend', bool)
    },
    onChangeSDV(item) {
      if (!this.isPreview) {
        return
      }
      buttonSDV({ pkId: item.pkId + '', flag: `${!item.sdvStatus}` })
        .then((res) => {
          item.sdvStatus = !item.sdvStatus
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onQuestion(item) {
      if (!this.isPreview) {
        return
      }
      this.addQAItem = item
      this.questionDialogShow = true
      this.questionTitle = `质疑：${item.ddName}`
      this.leftQAList = []
      getAllQuestionList({
        exampleId: item.exampleId,
        ddCode: item.ddCode,
        roleId: localStorage.getItem('user'),
      })
        .then((res) => {
          if (res.code === 200) {
            this.leftQAList = res.data
            this.tloading = false
            if (this.leftQAList.length) {
              this.setSend(false)
            }
            this.$refs.qtost.onScrollBottom()
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
        })
        .catch((error) => {
          this.tloading = false
          console.log(error)
        })
    },
    onSendquestion({ item, cb, i, user }) {
      this.questionDialogShow = true
      this.btnSendLoading = true
      addQuestion({
        valueId: item.code,
        content: item.content,
        b: item.b,
        solve: user,
        creatRole: this.$authorContainer.roleId,
      })
        .then((res) => {
          this.leftQAList = res.data
          this.setSend(false)
          this.btnSendLoading = false
          cb()
        })
        .catch((err) => {
          console.log(err)
          this.btnSendLoading = false
        })
    },
    addNewQA({ addData, cb, user }) {
      let params = {
        ddCode: this.addQAItem['ddCode'],
        exampleId: this.addQAItem['exampleId'],
        registryEntityCode: this.addQAItem['registryEntityCode'],
        content: addData.content,
        solve: user,
        creatRole: localStorage.getItem('user'),
      }
      addQuestionList(params)
        .then((res) => {
          if (res.code === 200) {
            this.leftQAList = res.data
            this.setSend(false)
            cb()
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onCloseQuestion(item) {
      let params = {
        id: item.id,
      }
      this.btnCloseLoading = true
      stopQuestionList({ id: item.code })
        .then((res) => {
          this.btnCloseLoading = false
          this.leftQAList = res.data
          this.setSend(false)
          if (res.code === 200) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
        })
        .catch((err) => {})
    },
    validator(rule, value, callback) {
      return false
    },
    handleNumItemClick() {},
    calItemFocus(item) {
      if (item.isCalculate == 1) this.autoCalculate = false
      this.currentNumItem = item
    },
    calItemBlur(item) {
      if (item.isCalculate == 1) this.autoCalculate = true
      this.currentNumItem = {}
    },
    // 打开弹出层,重复表单获取表单信息
    formDialog(val, item, idx, groupData) {
      if (val === 'add') {
        this.groupCode = idx.groupCode
        getStructureDdListGroup(this.multiFormParam).then((res) => {
          this.dialogStatus = 'create'
          let dataMap = res.data.ddMap
          for (let k in dataMap) {
            let item = dataMap[k]
            handleFormData(item, 'strToOther')
            this.dynamicFormDialog.singleSectionConditionMap[k] = {
              hided: item.visible == 0,
            }
          }
          this.dynamicFormDialog.sectionDDMap = dataMap
          this.formDialogVisible = true
        })
      } else if (val === 'edit') {
        let key = item[idx].id
        this.groupCode = groupData.groupCode
        this.customEditKey = key
        this.dialogStatus = 'update'
        this.groupExampleId = key
        this.ddValueIds = item.ddValueIds
        let editCon = Object.assign({}, groupData.groupMap)
        this.dynamicFormDialog.sectionDDMap = this.$options.data.call(
          this
        ).dynamicFormDialog.sectionDDMap
        this.dynamicFormDialog.singleSectionConditionMap = this.$options.data.call(
          this
        ).dynamicFormDialog.singleSectionConditionMap
        editCon[key] &&
          editCon[key].forEach((item) => {
            handleFormData(item, 'strToOther')
            this.dynamicFormDialog.sectionDDMap[item.ddCode] = item
            this.dynamicFormDialog.singleSectionConditionMap[item.ddCode] = {
              hided: item.visible == 0,
            }
          })
        this.formDialogVisible = true
      }
      if (val === 'check') {
        let key = item[idx].id
        let editCon = Object.assign({}, groupData.groupMap)
        this.dynamicFormDialog.sectionDDMap = this.$options.data.call(
          this
        ).dynamicFormDialog.sectionDDMap
        this.dynamicFormDialog.singleSectionConditionMap = this.$options.data.call(
          this
        ).dynamicFormDialog.singleSectionConditionMap
        editCon[key] &&
          editCon[key].forEach((item) => {
            handleFormData(item, 'strToOther')
            this.dynamicFormDialog.sectionDDMap[item.ddCode] = item
            this.dynamicFormDialog.singleSectionConditionMap[item.ddCode] = {
              hided: item.visible == 0,
            }
          })

        this.dialogStatus = 'check'
        this.formDialogVisible = true
      }
    },
    refresh() {
      this.$emit('refresh')
    },
    closeFrom(val) {
      if (val) {
        // if (this.dialogStatus !== "check") {
        //     // this.getSectionLists();
        //     this.$emit("refresh");
        // }
        // this.dynamicForm.sectionDDMap = {};
        this.dialogStatus = 'update'
        this.formDialogVisible = false
      }
    },
    // 删除多表
    removeForm(id, code) {
      console.log(id, code)
      deleteFormEntry(id)
        .then((res) => {
          this.$message({
            type: 'success',
            message: res.data,
          })
          // this.getSectionLists();
          if (res.code === 200 && code.groupCode) {
            this.delRefreshTable({ key: id, code: code.groupCode })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    tableHeaderCn() {
      return 'table_header'
    },
    clearFormValid(hided) {
      for (let g in this.dynamicForm.sectionDDMap) {
        let gr = this.dynamicForm.sectionDDMap[g]
        for (let gk in this.dynamicForm.sectionDDMap[g].groupMap) {
          let dd = this.dynamicForm.sectionDDMap[g].groupMap[gk]
          if (gr.isRepeat == 0) {
            dd.value = ''
          } else {
            this.dynamicForm.sectionDDMap[g].groupMap[gk] = {}
            // for (let d in dd) {
            //     dd[d].value = '';
            // }
          }
        }
      }
      this.$refs['dynamicForm'].clearValidate()
      this.handleOnload()
      if (hided) {
      }
    },
    onConfimNextPage() {
      this.$confirm('跳转下一表单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        distinguishCancelAndClose: false,
      })
        .then(() => {
          localStorage.setItem('isNext', 1)
          this.$emit('handleNodeClick', this.nextItem)
        })
        .catch(() => {
          localStorage.setItem('isNext', 0)
        })
    },
    handleConfirm({ n, s }) {
      if (!this.isPreview) {
        return
      }
      this.$refs[n].validate((valid) => {
        try {
          if (valid) {
            if (!s) {
              this.loading = true
            }
            addFormEntryGroup({
              ...this.params,
              userName: this.userData.userName,
              submit: this.isShowBTN ? 1 : 0,
            })
              .then((res) => {
                localStorage.removeItem('sectionDDMap')
                if (s && res.code === 200) {
                  console.log(111111, this.params)
                  this.$emit('handleSubmitConfirm', this.params)
                  this.firstIn = true
                  // if (this.nextItem) {
                  //     this.onConfimNextPage()
                  // }
                } else {
                  this.loading = false
                  if (this.formType == 'Single') this.$emit('refresh')
                  else this.$emit('closeFrom', true)
                }
              })
              .catch(() => {})
          }
        } finally {
          setTimeout(() => (this.loading = false), 1000)
        }
      })
    },
    cancelHandle() {
      // this.$emit("closeFrom", true);
      this.formDialogVisible = false
    },
    backtop() {
      this.$emit('backtop')
      let timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        osTop === 0 && clearInterval(timer)
      }, 30)

      // window.addEventListener('scroll', clearInterval(timer),true);
    },
    unique(arr) {
      return arr.reduce(
        (prev, cur) => (prev.includes(cur) ? prev : [...prev, cur]),
        []
      )
    },
    // 上传
    handleChange(file, fileList) {
      this.fileList = fileList
      if (this.fileList.length > 5)
        this.$message.warning(`不可上传多于5个文件，请删除其他文件后再上传。`)
    },
    handleSuccess(res, file, fileList) {
      this.$refs.dynamicForm.clearValidate()
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handlePreview(file) {
      let a = document.createElement('a')
      a.setAttribute('href', file.response.data[0].url)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
      // document.body.removeChild(a);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`不可上传多于5个文件，请删除其他文件后再上传。`)
      // this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const extension = testmsg === 'xls'
      // const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 5
      // if(!extension && !extension2) {
      //     this.$message({
      //         message: '上传文件只能是 xls、xlsx格式!',
      //         type: 'warning'
      //     });
      // }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 5MB!',
          type: 'warning',
        })
      }
      // return extension || extension2 && isLt2M
      return isLt2M
    },
    getConditionDdStruct(ddcode) {
      for (let g in this.dynamicForm.sectionDDMap) {
        for (let gk in this.dynamicForm.sectionDDMap[g].groupMap) {
          if (ddcode == gk) return this.dynamicForm.sectionDDMap[g].groupMap[gk]
        }
      }
      return null
    },
    setCBValues(dd, g) {
      let sectionDDMap = this.dynamicForm.sectionDDMap
      for (let g in sectionDDMap) {
        for (let gk in sectionDDMap[g].groupMap) {
          let ddd = sectionDDMap[g].groupMap[gk]
          if (ddd.controlType == 'CB' && ddd.visible == 0) {
            dd.value = []
          }
          console.log(dd.value)
        }
      }
    },
    handleCalculate(dd) {
      if (!dd.calMap) {
        return
      }

      let currentDdMap = dd.calMap.currentDdMap
      let sectionDDMap = this.dynamicForm.sectionDDMap

      for (let g in sectionDDMap) {
        for (let gk in sectionDDMap[g].groupMap) {
          let ddd = sectionDDMap[g].groupMap[gk]
          if (this.selCalculateDDTypeArr.indexOf(ddd.controlType) >= 0) {
            if (currentDdMap[ddd.ddCode] && currentDdMap[ddd.ddCode].required) {
              let options = ddd.itemOptionList

              let vals =
                (ddd.controlType == 'CB' ? ddd.value : ddd.value.split(',')) ||
                []

              let numValue = ''

              for (let i in options)
                if (vals.indexOf(options[i].value) >= 0) {
                  numValue = this.$isNum(numValue)
                    ? numValue + parseInt(options[i].value)
                    : parseInt(options[i].value)
                }
              currentDdMap[ddd.ddCode].value = numValue
            }
          }
          if (this.calculateDDTypeArr.indexOf(ddd.controlType) >= 0) {
            if (currentDdMap[ddd.ddCode] && currentDdMap[ddd.ddCode].required) {
              currentDdMap[ddd.ddCode].value = ddd.value
            }
          }
        }
      }
      dd.calMap.currentDdMap = currentDdMap
      return handleCalculate(dd)
    },

    setCalValue(g, gk, val) {
      console.log(111, val)
      this.dynamicForm.sectionDDMap[g].groupMap[gk].value = val
    },
    handleOnload() {
      // if (this.onload) {

      let arr = JSON.parse(localStorage.getItem('sectionDDMap'))
      if (arr && arr.length) {
        let inxArr = []
        arr.map((e, i) => {
          this.formData.sectionDDMap.map((ele, idx) => {
            if (ele.groupCode === e.groupCode) {
              inxArr.push(idx)
            }
          })
        })
        inxArr.map((e, i) => {
          this.formData.sectionDDMap[e] = arr[i]
        })
      }
      Array.from(this.formData.sectionDDMap).forEach((gItem) => {
        // console.log(gItem)

        let formList = []

        Object.keys(gItem.groupMap).length > 0
          ? Object.keys(gItem.groupMap).forEach((key) => {
              if (gItem.isRepeat == '1') {
                // console.log(key, 'gItem.groupMap[key]', gItem.groupMap[key]);
                let obj = {}
                let code = ''
                if (gItem.groupMap[key].forEach) {
                  gItem.groupMap[key].forEach((keyItem) => {
                    handleFormData(keyItem, 'strToOther')
                    code = keyItem.ddCode
                    if (keyItem.controlType === 'SEL_SIG') {
                      let selObj = keyItem.itemOptionList.filter(
                        (v) => keyItem.value === v.value
                      )
                      obj[code] =
                        selObj && selObj.length > 0 ? selObj[0].displayName : ''
                    } else if (keyItem.controlType === 'ADSEL') {
                      let addressArr = ''

                      keyItem.value.forEach((item) => {
                        addressArr += CodeToText[item]
                      })
                      obj[code] = addressArr
                    } else if (keyItem.controlType === 'CB') {
                      obj[code] = keyItem.value.join(',')
                    } else {
                      obj[code] = keyItem.value
                    }
                    obj['id'] = key
                    obj['ddValueIds'] = keyItem.ddValueIds || []
                    obj['groupCode'] = keyItem.groupCode
                  })

                  formList.push(obj)
                }
              } else {
                handleFormData(gItem.groupMap[key], 'strToOther') //多选[]
              }
              gItem.mFormData = formList
            })
          : (gItem.mFormData = [])
      })
      this.dynamicForm = { ...this.formData }
      if (!Object.keys(this.dynamicForm.sectionDDMap).length) {
        return
      }
      // this.dynamicForm.sectionDDMap.map((e) => {
      //     if (e.isRepeat == 1) {
      //         let ele = e.groupMap
      //         let arr = Object.values(ele).flat(Infinity)
      //     }
      // })
    },
    handleFirstIn(item) {
      this.firstIn = true
      this.customEditKey = item.exampleId
    },
    handleVisible(g, gk) {
      let dd = this.dynamicForm.sectionDDMap[g].groupMap[gk]
      if (dd == undefined) {
        return
      }
      // if (dd.controlType === "CB") {
      //     this.dynamicForm.sectionDDMap[g].groupMap[gk].value = []
      // }
      let hiden = true
      let visibleValue = ''
      let np = /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/
      let conditionExpression = dd.conditionExpression
      // console.log(conditionExpression);
      let match = this.unique(conditionExpression.match(this.ddCodeReg) || [])
      console.log('原始表达式---', conditionExpression, match);
      
      let isArrContidon = false
      let arrContidon = []
      match.forEach((code) => {
        let nk = code.slice(1, code.length - 1)
        let ddStruct = this.dynamicForm.sectionDDMap[g].groupMap[nk]

        if (!ddStruct) {
          this.dynamicForm.sectionDDMap.map((e) => {
            for (const key in e.groupMap) {
              if (e.groupMap.hasOwnProperty(key)) {
                if (key === nk) {
                  ddStruct = e.groupMap[key]
                  dd.operator = '{}'
                }
              }
            }
          })
        }
        if (typeof ddStruct != 'undefined') {
          visibleValue =
            ddStruct && ddStruct.value
              ? ddStruct.value
              : ddStruct.value === 0
              ? 0
              : code

          if (
            (ddStruct.controlType == 'SEL_SIG' ||
              ddStruct.controlType == 'RB' ||
              ddStruct.controlType == 'CB') &&
            ddStruct.itemOptionList
          ) {
            for (let i = 0; i < ddStruct.itemOptionList.length; i++) {
              let oo = ddStruct.itemOptionList[i]
              if (oo.value == visibleValue) {
                visibleValue = oo.displayName
              }
            }
          }
        }

        if (typeof visibleValue == 'object') {
          isArrContidon = true
          for (let i = 0; i < visibleValue.length; i++) {
            let nc = conditionExpression.replace(
              eval('/\\' + code + '/g'),
              np.test(visibleValue[i])
                ? visibleValue[i]
                : "'" + visibleValue[i] + "'"
            )
            arrContidon.push(nc)
          }
        } else {
          if (!np.test(visibleValue)) visibleValue = "'" + visibleValue + "'"
          conditionExpression = conditionExpression.replace(
            eval('/\\' + code + '/g'),
            visibleValue
          )
          // console.log('替换后的表达式------', conditionExpression);
        }
      })
      if (isArrContidon) {
        for (const cc of arrContidon) {
          let rarr = eval(cc)
          if (rarr) {
            this.dynamicForm.singleSectionConditionMap[gk].hided = false
            hiden = false
            break
          } else this.dynamicForm.singleSectionConditionMap[gk].hided = true
        }
        if (arrContidon.length == 0 && dd.operator == '{/}')
          this.dynamicForm.singleSectionConditionMap[gk].hided = false
        if (arrContidon.length == 0 && dd.operator == '{}')
          this.dynamicForm.singleSectionConditionMap[gk].hided = true
      } else {
        let r = !eval(conditionExpression)
        this.dynamicForm.singleSectionConditionMap[gk].hided = r
        hiden = r
      }
      return hiden
    },
  },
  watch: {
    qaIndex: function (o, n) {
      this.setSend(false)
    },
    'dynamicForm.sectionDDMap': {
      deep: true,
      handler: function (n, o) {
        for (let g in n) {
          let hiden = true

          if (n[g].isRepeat == 1) {
            let currentGroup = n[g]
            if (n[g].childV) {
              let ddCode = n[g].childV.ddCode

              let groupCode = n[g].childV.groupCode

              let index = n.findIndex((e) => {
                return e.groupCode == groupCode && e.isRepeat == 0
              })

              let h = this.handleVisible(
                index < 0 ? 0 : index,
                ddCode,
                n[g].childV.conditionExpression
              )
              this.dynamicForm.singleSectionConditionMap[
                n[g].groupCode
              ].hided = h
            } else {
              this.dynamicForm.singleSectionConditionMap[
                n[g].groupCode
              ].hided = false
            }
          } else {
            for (let gk in n[g].groupMap) {
              let dd = n[g].groupMap[gk]
              if (dd.isCalculate == 1) {
                let result = this.handleCalculate(dd)

                this.setCalValue(g, gk, result)
              }
              if (hiden && dd.visible == 1) hiden = false
              if (dd.visible == 0) {
                hiden = this.handleVisible(g, gk)
                n[g].groupMap[gk].hiden = hiden
              }
            }
          }
        }
        if (this.firstIn) this.firstIn = false
      },
    },

    onload: {
      handler: function () {
        if (this.onload) {
          this.handleOnload()
        }
      },
    },
  },
}
</script>
<style scoped lang="scss">
.el-image {
  margin-left: -20px;
  margin-right: 20px;
  margin-bottom: -6px;
}

/deep/ .table-wrap {
  padding-bottom: 20px;
}

.FormEntryForm {
  padding: 0 18px;
  /*height: 55vh;*/
  .el-input {
    width: 300px;
  }
  .el-textarea {
    width: 300px;
  }
  .formLabel {
    font-weight: bold;
  }
  .toTop {
    display: inline-block;
    text-align: center;
    width: 70px;
    height: 36px;
    margin-right: 10px;
    line-height: 36px;
    color: #0d83d8;
    font-size: 24px;
    vertical-align: top;
    background: rgba(9, 118, 196, 0.3);
    box-shadow: 0px 3px 4px 0px rgba(41, 77, 125, 0.3);
    border-radius: 2px;
  }
}

.formCon {
  background: #fff;
  padding: 0 15px;
  // margin: 15px 0;
  & > div {
    // padding: 10px 0;
  }
  .el-form-item {
    background: #ffffff;
    margin-bottom: 42px;
  }
  .el-input {
    width: 170px;
  }
  .el-textarea {
    width: 270px;
  }
  .formLabel {
    font-weight: bold;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .group-title {
    display: inline-block;
    font-size: 16px;
    color: #212121;
    line-height: 16px;
    margin-bottom: 8px;
  }
  /deep/ .el-input-number--small .el-input__inner {
    padding-left: 0;
  }
}

.tag-wrap {
  position: relative;
  .tag-top-wrap {
    position: absolute;
    right: 0;
    top: 4px;
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss">
.table_header {
  background-color: rgba(44, 153, 255, 0.15) !important;
  border-right: none !important;
}

.bottom-btn-wrap {
  background: #fff;
  background: #fff;
  padding: 10px 20px;
  box-shadow: 0px 0px 3px #ccc;
  border-radius: 4px;
}
.unit-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  background: #f5f7fa;
  border-radius: 0 4px 4px 0;
  color: #606266;
  cursor: pointer;
  font-size: 13px;
  height: 32px;
  border: 1px solid #dcdfe6;
  margin-left: -4px;
}
.unit-boxl {
  border-radius: 4px 0 0 4px;
  margin-right: -4px;
}
</style>

