<template>
  <div class="FormEntryForm">

    <el-scrollbar>
      <el-form ref="dynamicForm" :model="dynamicForm" label-position="top" size="small">
        <div class="formCon" v-for="(item,key) in dynamicForm.sectionDDMap" :key="key">
          <!-- LABEL -->
          <p class="formLabel" v-if="item.controlType==='LB'" :style="{fontSize:item.numConfig}">
            {{item.ddName}}</p>
          <!--单文本-->
          <el-form-item v-if="(item.controlType==='TB'||item.controlType==='NTX'&&item.unitOfMeasure)&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-input :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" :id="item.ddCode" type="input" v-model="item.value">
              <template slot="append" v-if="item.unitOfMeasure">{{item.unitOfMeasure}}</template>
            </el-input>
          </el-form-item>

          <!-- 多行文本 -->
          <el-form-item v-if="item.controlType==='TA'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="[ {required: item.isImExport===1, message: `${item.ddName}不能为空`, trigger: 'blur'},
                            { max: 255, message: '最多不超过255个字', trigger: 'blur' } ]">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-input :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" type="textarea" v-model="item.value" rows="2"></el-input>
          </el-form-item>

          <!-- 单选 -->
          <el-form-item v-if="item.controlType==='RB'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-radio-group :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value">
              <template v-for="(listItem,index) in item.itemOptionList">
                <el-radio :key="index" :label="listItem.displayName">
                  {{listItem.displayName}}
                </el-radio>
                <!-- TODO 测试结束更改路径-->
                <el-image :key="index+listItem.optionSetImgUrl" style="width: 20px; height: 20px" :src="imageNameFormat(listItem.optionSetImgUrl,'0')" :preview-src-list="imageNameFormat(listItem.optionSetImgUrl,'1')" v-if="listItem.optionSetImgUrl">
                </el-image>
              </template>
            </el-radio-group>
          </el-form-item>
          <!-- 多选 -->
          <el-form-item :readonly="item.dataType==1" v-if="item.controlType==='CB'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-checkbox-group :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value">
              <template v-for="(listItem,index) in item.itemOptionList">
                <div class="el-checkbox" :key="index">
                  <el-checkbox :label="listItem.displayName">
                    {{listItem.displayName}}
                  </el-checkbox>
                </div>
                <el-image :key="index+listItem.optionSetImgUrl" style="width: 20px; height: 20px" :src="imageNameFormat(listItem.optionSetImgUrl,'0')" :preview-src-list="imageNameFormat(listItem.optionSetImgUrl,'1')" v-if="listItem.optionSetImgUrl">
                </el-image>

              </template>
            </el-checkbox-group>
          </el-form-item>

          <!-- 下拉 -->
          <el-form-item v-if="item.controlType==='SEL_SIG'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

            <el-select :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value" filterable placeholder="请选择" style="width:100%">
              <el-option v-for="listItem in item.itemOptionList" :key="listItem.value" :label="listItem.displayName" :value="listItem.value">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 日期选择 -->
          <el-form-item v-if="item.controlType==='DP'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

            <el-date-picker @blur="calItemBlur(item)" @focus="calItemFocus(item)" :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" value-format="yyyy-MM-dd" v-model="item.value" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <!-- 时间选择 -->
          <el-form-item v-if="item.controlType==='TP'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

            <el-time-picker @blur="calItemBlur(item)" @focus="calItemFocus(item)" :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" v-model="item.value" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
          </el-form-item>

          <!-- 时间日期选择 -->
          <el-form-item v-if="item.controlType==='DTP'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-date-picker @blur="calItemBlur(item)" @focus="calItemFocus(item)" :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" value-format="yyyy-MM-dd HH:mm:ss" v-model="item.value" type="datetime" placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>

          <!-- 数字 -->
          <el-form-item v-if="item.controlType==='NTX'&&!item.unitOfMeasure&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

            <el-input :readonly="item.dataType==1" type="number" v-model="item.value" @blur="calItemBlur(item)" @focus="calItemFocus(item)" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')">
            </el-input>
          </el-form-item>

          <!-- 文件上传 -->
          <el-form-item v-if="item.controlType==='SUF'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />

            <el-upload :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" action="/api/ddValue/upload" name="files" :on-change="handleChange" :on-success="handleSuccess" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="item.fileList" multiple :limit="5" :on-exceed="handleExceed" :beforeUpload="beforeAvatarUpload">
              <el-button slot="trigger" size="mini" type="primary" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')">
                文件选取
              </el-button>
            </el-upload>
          </el-form-item>

          <!-- 地址选择 -->
          <el-form-item v-if="item.controlType==='ADSEL'&&!dynamicForm.singleSectionConditionMap[key].hided" :prop="'sectionDDMap.' + key + '.value'" :rules="itemRule(item)">
            <FormLabel slot="label" :label="item.ddName" :content="item.ddInputGuide" />
            <el-cascader :readonly="item.dataType==1" :disabled="(formType=='Single'&&formStatus!=0)||(formType=='Multiple'&&dialogStatus=='check')" size="normal" style="width:270px" :options="regionOptions" v-model="item.value">
            </el-cascader>
          </el-form-item>
          <!-- <el-form-item v-if="item.remark" label="备注:">
                        <el-input  type="textarea" v-model="item.remark"  :autosize="{ minRows: 2, maxRows: 4}"  placeholder="请输入备注"></el-input>
                        <el-popconfirm title="确定删除此备注吗？" @confirm = "handleNote(item,0)">
                            <i slot="reference" style="cursor: pointer;margin-left:20px;" class="el-icon-delete" ></i>
                        </el-popconfirm>
                    </el-form-item> -->
        </div>
      </el-form>
    </el-scrollbar>
    <div style="text-align:right;padding:10px 0">
      <el-button v-if="dialogStatus!=='check'" @click="cancelHandle" :disabled="(formType=='Single'&&formStatus!=0)" size="mini">取消
      </el-button>
      <el-button v-if="dialogStatus!=='check'" type="primary" @click="handleConfirm('dynamicForm')" :disabled="(formType=='Single'&&formStatus!=0)" :loading="loading" size="mini">
        保存
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  getSectionList,
  getTreeNodeData,
  addFormEntry,
  addFormEntryGroup,
} from '@/api/formEntry'
import { handleFormData, formatDate } from '@/utils/index'
import { handleCalculate } from '../utils/logicCalculate'
import { regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import FormLabel from './FormItemLabel'
import { mapState } from 'vuex'

export default {
  name: 'FormEntryForm',
  props: [
    'isShowBTN',
    'sectionList',
    'itemStatus',
    'isIndex',
    'researchCode',
    'editKey',
    'exampleId',
    'parentExampleId',
    'formData',
    'formType',
    'dialogStatus',
    'formStatus',
    'groupCode',
    'handleItem',
    'changePatientId',
    'ddValueIds',
  ],
  mounted() {
    this.dynamicForm = {}
    this.dynamicForm = this.formData
    // this.clearFormValid();
  },
  computed: {
    ...mapState(['userData']),
    isPreview: function () {
      return !this.$route.meta.isPreview
    },
    itemRule(item) {
      console.log(item)
      return (item) => {
        let rules = [
          {
            required: item.isImExport === 1,
            message: `${item.ddName}不能为空`,
            trigger: ['blur', 'change'],
          },
        ]
        if (item.controlType == 'NTX') {
          console.log(item)
          rules.push({
            validator: (rule, value, callback) => {
              if (value) {
                let np = /^(-)?\d+(\.\d+)?$/
                if (!np) callback(new Error('数字输入有误'))
                let nc = item.numConfig
                if (nc) {
                   if (typeof nc === 'string') {
                   nc = JSON.parse(nc)
                  } 
                  if (nc.allowDecimal == 0) {
                    if (value.toString().indexOf('.') >= 0)
                      callback(new Error('不允许输入小数'))
                  } else {
                    console.log(1234,value.toString());
                    if (value.toString().indexOf('.') >= 0) console.log(nc)
                    if (
                      value.toString().split('.').length&& value.toString().split('.')[1]&&value.toString().split('.')[1].length > nc.decimalDigits
                    ) {
                      callback(
                        new Error('仅允许' + nc.decimalDigits + '位小数')
                      )
                    }
                  }
                  if (
                    nc.scopeValid == 1 &&
                    (parseFloat(value) > parseFloat(nc.scopeRange_up) ||
                      parseFloat(value) < parseFloat(nc.scopeRange_down))
                  )
                    callback(
                      new Error(
                        '数值范围输入有误! 数值范围:{' +
                          nc.scopeRange_down +
                          ',' +
                          nc.scopeRange_up +
                          '}'
                      )
                    )
                }
              }
              callback()
            },
            trigger: ['change', 'blur'],
          })
        }
        return rules
      }
    },
    params() {
      let list = []
      Object.values(this.dynamicForm.sectionDDMap).forEach((item) => {
        if (this.formType == 'Single')
          handleFormData(item, 'toStr', this.fileList)
        else handleFormData(item, 'toStr')
        console.log(item)
        list.push({
          name: item.ddCode,
          //修改数据时,如果字段值满足条件,修改后不满足,则保存的时候会把隐藏的字段值设置为 ''
          value: this.dynamicForm.singleSectionConditionMap[item.ddCode].hided
            ? ''
            : item.value1
            ? item.value1.join(',')
            : item.value,
          pkId: item.pkId,
          groupCode: item.groupCode,
          exampleId: item.exampleId,
          status: item.status,
        })
      })
      return {
        sections: [
          {
            ddValueIds: this.ddValueIds || [],
            exampleId: this.exampleId,
            parentExampleId: this.parentExampleId,
            sectionCode: this.handleItem.code,
            sectionParentCode: this.handleItem.parentCode,
            registyEntityCode: this.researchCode,
            patientId: this.changePatientId,
            mode: this.dialogStatus === 'update' ? 'UPDATE' : 'CREATE',
            // status: (this.dialogStatus === "update" ? this.itemStatus : "0"),
            ddValueList: list,
            formType: this.formType,
            groupCode: this.groupCode || '',
            // status: 0,
          },
        ],
      }
    },
    imageNameFormat(name, type) {
      return (name, type) => {
        let imageName = ''
        if (name) {
          imageName = this.$host + name.substr(name.lastIndexOf('/'))
        }
        if (type === '0') {
          return imageName
        } else {
          var myArray = []
          myArray.push(imageName)
          return myArray
        }
      }
    },
  },

  data() {
    return {
      firstIn: true,
      autoCalculate: true,
      calculateDDTypeArr: ['NTX', 'DP', 'TP', 'DTP'],
      selCalculateDDTypeArr: ['RB', 'CB', 'SEL_SIG'],

      fileList: [],
      dynamicForm: {
        singleSectionConditionMap: {},
        sectionDDMap: {},
      },
      loading: false,
      regionOptions: regionDataPlus,
    }
  },
  methods: {
    handleNote(item, type) {},
    clearFormValid(hided) {
      for (let g in this.dynamicForm.sectionDDMap) {
        this.dynamicForm.sectionDDMap[g].value = ''
      }
      this.$refs['dynamicForm'].clearValidate()
      if (hided) {
      }
    },
    handleConfirm(formName) {
      if (!this.isPreview) {
        return
      }
      this.$refs[formName].validate((valid) => {
        try {
          if (valid) {
            this.params.value = this.params.value1
            this.loading = true
            console.log(11111, this.isShowBTN, this.groupCode)
            !this.groupCode
              ? addFormEntry({
                  ...this.params,
                  userName: this.userData.userName,
                  submit: this.isShowBTN,
                }).then((res) => {
                  this.$message({
                    type: 'success',
                    showClose: true,
                    message: res.msg,
                    center: true,
                  })
                  if (this.formType == 'Single') {
                    this.$emit('refresh')
                  } else {
                    this.$emit(
                      'closeFrom',
                      true,
                      this.dialogStatus === 'update' ? 0 : 1
                    )
                  }

                  this.firstIn = true
                })
              : addFormEntryGroup({
                  ...this.params,
                  submit: this.isShowBTN ? 1 : 0,
                }).then((res) => {
                  this.$message({
                    type: 'success',
                    showClose: true,
                    message: res.msg,
                    center: true,
                  })
                  // if (this.formType == 'Single')
                  let resData = res.data
                  const groupCode = resData.groupCode
                  const pkKey = Object.keys(resData)[0]
                  const pkValue = Object.values(resData)[0]
                  pkValue.map((e) => {
                    if (e.controlType == 'ADSEL') {
                      e.value = e.value.split(',')
                    }
                  })
                  let obj = {
                    [pkKey]: pkValue,
                    groupCode,
                  }
                  console.log(obj)
                  this.$emit('refreshTable', obj)
                  // else
                  // this.$emit("closeFrom", true);
                  this.firstIn = true
                })
          }
        } finally {
          setTimeout(() => (this.loading = false), 1000)
        }
      })
    },

    cancelHandle() {
      this.$emit('closeFrom', true)
    },
    unique(arr) {
      return arr.reduce(
        (prev, cur) => (prev.includes(cur) ? prev : [...prev, cur]),
        []
      )
    },
    // 上传
    handleChange(file, fileList) {
      this.fileList = fileList
      if (this.fileList.length > 5)
        this.$message.warning(`不可上传多于5个文件，请删除其他文件后再上传。`)
    },
    handleSuccess(res, file, fileList) {
      this.$refs.dynamicForm.clearValidate()
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handlePreview(file) {
      let a = document.createElement('a')
      a.setAttribute('href', file.response.data[0].url)
      a.setAttribute('target', '_blank')
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`不可上传多于5个文件，请删除其他文件后再上传。`)
      // this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const extension = testmsg === 'xls'
      // const extension2 = testmsg === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 5
      // if(!extension && !extension2) {
      //     this.$message({
      //         message: '上传文件只能是 xls、xlsx格式!',
      //         type: 'warning'
      //     });
      // }
      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 5MB!',
          type: 'warning',
        })
      }
      // return extension || extension2 && isLt2M
      return isLt2M
    },
    handleVisible(index) {
      let value = ''
      let np = /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/
      let conditionExpression = this.dynamicForm.sectionDDMap[index]
        .conditionExpression
      let match = conditionExpression.match(/#(.+?)#/g) || []
      match = this.unique(match)
      match.forEach((code) => {
        let ddStruct = this.dynamicForm.sectionDDMap[
          code.slice(1, code.length - 1)
        ]
        value = ddStruct && ddStruct.value ? ddStruct.value : code

        if (
          (ddStruct.controlType == 'SEL_SIG' ||
            ddStruct.controlType == 'RB' ||
            ddStruct.controlType == 'CB') &&
          ddStruct.itemOptionList
        ) {
          console.log('ddStruct', ddStruct)
          for (let i = 0; i < ddStruct.itemOptionList.length; i++) {
            let oo = ddStruct.itemOptionList[i]
            if (oo.value == value) {
              value = oo.displayName
            }
          }
        }
        if (!np.test(value)) value = "'" + value + "'"
        conditionExpression = conditionExpression.replace(
          eval('/\\' + code + '/g'),
          value
        )
      })
      console.log(this.dynamicForm.sectionDDMap[index])
      //控制显示隐藏
      if (
        this.dynamicForm.singleSectionConditionMap[
          this.dynamicForm.sectionDDMap[index].ddCode
        ]
      )
        this.dynamicForm.singleSectionConditionMap[
          this.dynamicForm.sectionDDMap[index].ddCode
        ].hided = !eval(conditionExpression)
    },
    calItemFocus(item) {
      if (item.isCalculate == 1) this.autoCalculate = false
    },
    calItemBlur(item) {
      if (item.isCalculate == 1) this.autoCalculate = true
    },
    handleFirstIn() {
      this.firstIn = true
    },
    handleCalculate(dd) {
      let sectionDDMap = this.dynamicForm.sectionDDMap
      let currentDdMap = dd.calMap.currentDdMap
      for (let i in sectionDDMap) {
        let dd = sectionDDMap[i]
        if (this.selCalculateDDTypeArr.indexOf(dd.controlType) >= 0) {
          if (currentDdMap[dd.ddCode] && currentDdMap[dd.ddCode].required) {
            let options = dd.itemOptionList

            let vals =
              (dd.controlType == 'CB' ? dd.value : dd.value.split(',')) || []

            let numValue = Number(0)

            // if (dd.controlType == 'SEL_SIG')
            //     numValue = parseInt(dd.value);
            // else
            for (let i in options)
              if (vals.indexOf(options[i].value) >= 0)
                numValue += parseInt(options[i].value)

            currentDdMap[dd.ddCode].value = numValue
          }
        }
        if (
          this.calculateDDTypeArr.indexOf(dd.controlType) >= 0 &&
          currentDdMap[dd.ddCode] &&
          currentDdMap[dd.ddCode].required &&
          dd.value
        ) {
          currentDdMap[dd.ddCode].value = dd.value
        }
      }
      dd.calMap.currentDdMap = currentDdMap
      return handleCalculate(dd)
    },

    setCalValue(k, val) {
      this.dynamicForm.sectionDDMap[k].value = val
    },
  },
  watch: {
    'dynamicForm.sectionDDMap': {
      deep: true,
      handler: function (n, o) {
        for (let i in n) {
          /*
                        条件显示,目前只有一个条件
                        后端返回表达式,由前端验证条件
                            约束条件在同表
                                表达式为: #约束条件的ddCode# 操作符(< >等) 条件值
                            约束条件在异表
                                表达式为: 约束值 操作符(<,>等)  条件值
                         */
          let dd = n[i]
          if (n[i].visible == 0) {
            this.handleVisible(i)
          }

          if (dd.isCalculate == 1) {
            // if (this.autoCalculate && !this.firstIn) {
            let result = this.handleCalculate(dd)
            this.setCalValue(i, result)
            // }
          }
        }

        if (this.firstIn) this.firstIn = false
      },
    },
  },

  components: { FormLabel },
}
</script>
<style scoped lang="scss">
.el-image {
  margin-left: -20px;
  margin-right: 20px;
  margin-bottom: -6px;
}

.FormEntryForm {
  padding: 0 18px;
  /*height: 55vh;*/
  .el-input {
    width: 300px;
  }
  .el-textarea {
    width: 300px;
  }
  .formLabel {
    font-weight: bold;
  }
}

.formCon {
  .el-form-item {
    background: #ffffff;
  }
  .el-input {
    width: 270px;
  }
  .el-textarea {
    width: 270px;
  }
  .formLabel {
    font-weight: bold;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
</style>

