import {formatDate} from './index'
import globalUtil from './globalUtil'
import {calculatorConst} from './globalConst'
import dayjs from "dayjs"
let outerDdMap = {};
let currentDdMap = {};
let calArr = [];
let ddItem = {};
let calOper = ["+", "-", "*", "/"];
let resultType, dateUnit;
 
export function handleCalculate(dd) {
    console.log('计算 dd', dd);
    outerDdMap = dd.calMap.outerDdMap;
    currentDdMap = dd.calMap.currentDdMap;
    calArr = dd.calMap.calculate;
    ddItem = dd
    resultType = dd.calMap.type;
    dateUnit = dd.calMap.dateUnit || 1;
 
    let canCalculateRes = canCalculate();
    // console.log(currentDdMap, outerDdMap, 'canCalculate', canCalculateRes);
    if (canCalculateRes) {
        let result = doCalculate(calArr);
        // 数值类型
        if (dd.controlType == 'NTX') {
            if (resultType == 1) {
                
            }
 
            //时间间隔 默认返回 秒
            if (resultType == 2 && result) {
                try {
                    if (dateUnit == 1)
                        result = Math.floor(result / (60 * 60 * 24 * 31 * 12));
                    if (dateUnit == 2)//月
                        result = Math.floor(result / (60 * 60 * 24 * 31));
                    if (dateUnit == 3)//日
                        result = Math.floor(result / (60 * 60 * 24));
                    if (dateUnit == 4)//时
                        result = Math.floor(result / (60 * 60));
                    if (dateUnit == 5)//分
                        result = Math.floor(result / 60);
                } catch (e) {
                    globalUtil.errInfo("时间计算失败", 'warn')
                    result = 0;
                }
            }
 
            if (Object.keys(dd.numConfig).length > 0)
                result = globalUtil.ntxScopeCk(result, dd.numConfig);
        }
        return result;
    }
    return ''
}
 
function isUndefind(o, a) {
    return !((typeof o == 'object' && o.hasOwnProperty("value") && globalUtil.isNum(o.value))
        || (typeof o == 'object' && o.hasOwnProperty("value") && o.value)
    )
}
 
function doCalculate(expArray) {

    let s = [];
    let t = -1;
    for (let i in expArray) {
        let ele = expArray[i];
        if (ele.startsWith("Math"))
            ele = handleMathExp(ele);
// √￣   ²   ³
        if (isSingleDdcode(ele) && !globalUtil.isNum(ele))
            if (!isUndefind(currentDdMap[ele]))
                ele = currentDdMap[ele].value;
            else if (!isUndefind(outerDdMap[ele], 3))
                ele = outerDdMap[ele].value;
 
        if (isOper(ele)) {
            let var1 = s[t--];
            let var2 = s[t--];
            if (globalUtil.isNum(var1) && globalUtil.isNum(var2)) {
                if (ele == '/' && var1 == 0)
                    s[++t] = 0;
                else
                    s[++t] = eval(var2 + ele + var1);
            } else if ((isTime(var1) && isTime(var2))) {
                s[++t] = handleDTSub(var2, var1, 'TP');
            } else if ((isDate(var1) || isDatetime(var1)) && (isDate(var2) || isDatetime(var2))) {
                // s[++t] = handleDTSub(var2, var1, 'DP');
                try {
                    const vt2 = var2.split(' ').length>1? new Date(var2).getTime() + 8*1000*60*60:new Date(var2).getTime()
                    const vt1 = var1.split(' ').length>1? new Date(var1).getTime() + 8*1000*60*60:new Date(var1).getTime()
                    const dt = (vt2 -vt1)/1000
                    s[++t] = Math.abs(dt)
                } catch (error) {
                    
                }
                
            } else {
                s[++t] = (isTime(var1) && globalUtil.isNum(var2)) || (isTime(var2) && globalUtil.isNum(var1))
                    ? (isTime(var1)
                        ? timeCal(var1, var2, ele)
                        : timeCal(var2, var1, ele))
                    : ((isDatetime(var1) || isDate(var1))
                        ? dateCal(var1, var2, ele, getDdType(var1))
                        : dateCal(var2, var1, ele, getDdType(var2)));
            }
        } else {
            s[++t] = ele;
        }
    }
    return s[t];
}
 
let ia = 100000;
 
//验证是否满足计算条件,如果某个表达式的dd值为空,则不进行计算
function canCalculate() {

    //表示直接计算平方 开方等单运算符计算
    if (ddItem.calMap.canCalculate)
        return true;
 
    if (!outerDdMap || !currentDdMap)
        return false;
 
 
    for (let o in outerDdMap)
        if (isUndefind(outerDdMap[o]))
            return false;
    for (let o in currentDdMap)
        if (isUndefind(currentDdMap[o]))
            return false;
 
    return true;
}
 
function isOper(ele) {
    return calOper.indexOf(ele) >= 0;
}
 
 
function isTime(n) {
    return new RegExp(/^(?:\d{1,2}):\d{2}$/).test(n);
}
 
function isDate(n) {
    
    n = dayjs(n).format('YYYY-MM-DD')
    return new RegExp(/\b(\d{4})-(\d{2})-(\d{2})\b/).test(n);
}
 
function isDatetime(n) {
    
    n = dayjs(n).format('YYYY-MM-DD hh:mm:ss')
    return new RegExp(/\b(\d{4})-(\d{2})-(\d{2})\b (?:\d{2}):\d{2}:\d{2}$/).test(n);
}
 
//处理时间日期差 返回单位为秒
function handleDTSub(t1, t2, calType) {
    let r2 = '';
    if (calType == 'TP') {
        let d1 = new Date("1000-10-10 " + t1);
        let d2 = new Date("1000-10-10 " + t2);
        r2 = (d1 - d2) / 1000;
    } else if (calType == 'DP' || calType == 'DTP') {
        // let d1 = new Date(t1.slice(0, 10))
        // let d2 = new Date(t2.slice(0, 10))
        r2 = Math.floor((new Date(t1) - new Date(t2)) / 1000);
    }
    return r2;
}
 
function timeCal(time, num, oper) {
    let d1 = new Date("1000-10-10 " + time);
 
    if (dateUnit == calculatorConst.dateUnit.HOUR)
        num = num * 1000 * 60 * 60;
    if (dateUnit == calculatorConst.dateUnit.MINUTE)
        num = num * 1000 * 60;
    let d3 = new Date(getTimeCalRes(d1.getTime(), num, oper));
    let a = d3.getMinutes();
    let r1 = d3.getHours() + ":" + (a > 10 ? a : '0' + a);
    return r1.indexOf("NaN") >= 0 ? '' : r1;
}
 
function getTimeCalRes(n1, n2, oper) {
    switch (oper) {
        case '+':
            return n1 + n2;
        case '-':
            return n1 - n2;
        case '*':
            return n1 * n2;
        case '/':
            return n1 / n2;
    }
}
 
function dateCal(date, num, oper, currentResultType) {
 
    if (dateUnit == calculatorConst.dateUnit.YEAR)
        num = num * 1000 * 60 * 60 * 24 * 12 * 365;
    if (dateUnit == calculatorConst.dateUnit.MONTH)
        num = num * 1000 * 60 * 60 * 24 * 12;
    if (dateUnit == calculatorConst.dateUnit.DAT)
        num = num * 1000 * 60 * 60 * 24;
    if (dateUnit == calculatorConst.dateUnit.HOUR)
        num = num * 1000 * 60 * 60;
    if (dateUnit == calculatorConst.dateUnit.MINUTE)
        num = num * 1000 * 60;
 
    let d1 = new Date(getTimeCalRes(new Date(date).getTime(), num, oper));
 
    let r3 = formatDate(d1, currentResultType == 'DTP' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy/MM/dd');
    return r3.indexOf("NaN") >= 0 ? '' : r3;
}
 
 
function getDdType(ddValue) {
    if (globalUtil.isNum(ddValue))
        return "NTX";
    else if (isTime(ddValue))
        return "TP";
    else if (isDate(ddValue))
        return "DP";
    else if (isDatetime(ddValue))
        return "DTP";
    else
        return '';
}
 
function handleMathExp(ele, ddReg = /\b[a-z0-9]{16}\b/gi) {
    let match = ele.match(ddReg) || [];
    let e2 = ele;
    for (let i in match)
        e2 = e2.replace(match[i], isUndefind(outerDdMap[match[i]]) ? currentDdMap[match[i]].value : outerDdMap[match[i]].value)
    return eval(e2);
}
 
function isSingleDdcode(n) {
    return /[a-z0-9]{16}$/.test(n);
}